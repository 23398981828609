import './App.css';
import {CssBaseline} from "@mui/material";
import DrawerAppBar from "./components/AppBar/DrawerAppBar";
import * as React from "react";
import {useState} from "react";
import {createTheme, responsiveFontSizes, ThemeProvider} from "@mui/material/styles";
import {BASE_THEME_CUSTOMIZATION} from "./assets/theme/ThemeConstants";
import {BrowserRouter, Route} from "react-router-dom";
import CacheBuster from "./CacheBuster";
import packageV from "../package.json"
global.appVersion = packageV.version;

const defaultTheme = createTheme();
const customTheme = (theme) => {

    return responsiveFontSizes(createTheme(theme(defaultTheme)))
};


function App() {
    let [state] = useState({
        theme: customTheme(BASE_THEME_CUSTOMIZATION),
    })
    return (<CacheBuster>
            <ThemeProvider theme={state.theme}>
                <CssBaseline enableColorScheme/>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <Route exact path={""}>
                        <DrawerAppBar
                        />
                    </Route>
                </BrowserRouter>

            </ThemeProvider>
        </CacheBuster>
    );
}

export default App;
