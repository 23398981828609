import React, {Component} from 'react';
import {Button, Grid, Stack, TextField, Typography} from "@mui/material"

class JSONparser extends Component {

    state = {
        oldJsonObj: "{}",
        newJsonObj: "{}",
        finalJson: null
    }


    parseAll = (fillEmptyOnly) => {
        let {oldJsonObj, newJsonObj} = this.state
        let oldParsed = JSON.parse(oldJsonObj)
        let newParsed = JSON.parse(newJsonObj)
        let finalJson = {};
        for (let i in oldParsed) {
            let oldItem = oldParsed[i];
            let newItem = newParsed[i];

            if (fillEmptyOnly) {
                if (newItem === "") {
                    finalJson[i] = oldItem;
                }
            } else {
                if (newItem === "" || newItem) {
                    finalJson[i] = oldItem;
                }
            }


        }
        this.setState({finalJson: JSON.stringify(finalJson)})
    }

    render() {
        let {oldJsonObj, newJsonObj, finalJson} = this.state
        let itemStyle = {padding: "20px"}
        return (
            <Grid container spacing={0} style={{padding: "20px", overflow: "auto"}}>
                <Grid item xs={12} style={itemStyle}>

                    <Stack direction={"row"} width={"100%"}>
                        <Typography variant={"title"}>OLD JSON</Typography>
                        <Button color={"primary"}
                                onClick={async () => {
                                    let tmp = await navigator.clipboard.readText()
                                    this.setState({oldJsonObj: tmp})
                                }} variant={"contained"}> Paste</Button>
                    </Stack>
                </Grid>

                <Grid item xs={12} style={itemStyle}>

                    <TextField
                        value={oldJsonObj}
                        multiline
                        variant="outlined"
                        fullWidth
                        rows="8"
                        onChange={(e) => {
                            let oldJsonObj = e.target.value
                            this.setState({oldJsonObj})
                        }}
                    /></Grid>
                <Grid item xs={12} style={itemStyle}>
                    <Stack direction={"row"} width={"100%"}>
                        <Typography variant={"title"}>NEW JSON</Typography>
                        <Button color={"primary"}
                                onClick={async () => {
                                    let tmp = await navigator.clipboard.readText()
                                    this.setState({newJsonObj: tmp})
                                }} variant={"contained"}> Paste</Button>
                    </Stack>
                </Grid>
                <Grid item xs={12} style={itemStyle}>
                    <TextField
                        value={newJsonObj}
                        multiline
                        variant="outlined"
                        fullWidth
                        rows="8"
                        onChange={(e) => {
                            let newJsonObj = e.target.value
                            this.setState({newJsonObj})
                        }}
                    /></Grid>
                <Grid item xs={12} style={itemStyle}>
                    <Button variant={"contained"} color={"primary"}
                            onClick={this.parseAll}>Fill all the new JSON keys, will override all the
                        translations</Button>
                </Grid>
                <Grid item xs={12} style={itemStyle}>
                    <Button variant={"contained"} color={"primary"}
                            onClick={() => this.parseAll(true)}>Fill only the empty new JSON keys</Button>
                </Grid>
                {
                    finalJson && <><Grid item xs={12} style={itemStyle}>
                        <TextField
                            value={finalJson}
                            rows="8"
                            variant="outlined"
                            fullWidth
                            multiline
                        /></Grid>
                        <Grid item xs={12} style={itemStyle}>
                            <Button color={"primary"}
                                    onClick={() => {
                                        navigator.clipboard.writeText(finalJson)
                                    }} variant={"contained"}>Copy</Button></Grid></>
                }

            </Grid>
        );
    }
}

export default JSONparser;

