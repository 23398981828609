import {useEffect, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    CircularProgress,
    Grid,
    Paper,
    Stack,
    Tooltip
} from "@mui/material";
import axios from "axios";
import {ArrowForward, ExpandMore, Login, OpenInNew, Person} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {SimpleDialog} from "../SimpleDialog/SimpleDialog";

const Products = () => {
    let [productsL1, setProductsL1] = useState([])
    let [productsL2, setProductsL2] = useState([])
    let [productsL3, setProductsL3] = useState([])
    let [loading, setLoading] = useState(true)
    useEffect(() => {
        (async () => {
            let basePath = getBasePath("L1")
            try {
                let res = await axios.get(basePath + "/api/v1/config/product")
                setProductsL1(res.data)
            } catch (e) {

            }
            try {
                // basePath = getBasePath("L2")
                // let res2 = await axios.get(basePath + "/api/v1/config/product")
                // setProductsL2(res2.data)
            } catch (e) {

            }
            try {
                basePath = getBasePath("L3")
                let res3 = await axios.get(basePath + "/api/v1/config/product")
                setProductsL3(res3.data)
            } catch (e) {

            }


            setLoading(false)

        })()
    }, [])

    return loading ? <Backdrop open={loading}><CircularProgress/></Backdrop> : <Stack width={"100%"}>
        {productsL1.map((product) => {

            return <Product
                key={product.id}
                product={product}
                productL2={productsL2.find(x => x.id === product.id)}
                productL3={productsL3.find(x => x.id === product.id)}
            />
        })}

    </Stack>
}

export default Products

const Product = ({product, productL2 = {}, productL3 = {}}) => {
    let [expandedP, setExpanded] = useState(JSON.parse(sessionStorage.getItem(product.id + "Expanded")))


    return <Accordion

        expanded={expandedP}
        onChange={async (event, expanded) => {
            sessionStorage.setItem(product.id + "Expanded", expanded)
            setExpanded(expanded)
        }}

    >
        <AccordionSummary
            sx={{
                pointerEvents: "none"
            }}
            expandIcon={<ExpandMore
                sx={{
                    pointerEvents: "auto"
                }}
            />}

            aria-controls="panel1a-content"
            id="panel1a-header"
        >

            <Stack sx={{padding: "10px"}} width={"100%"}
                   onClick={(event) => {
                       event.preventDefault()
                       event.stopPropagation()
                   }}
            >
                <Stack direction={"row"} width={"100%"} alignItems={"center"}
                >
                    <img src={"data:image/png;base64, " + product.logo} alt={"logo"}
                         style={{width: "50px", height: "50px", marginRight: "10px"}}/>
                    <Typography variant={"title"}>
                        {product.name}
                    </Typography>
                </Stack>

            </Stack>
        </AccordionSummary>
        <AccordionDetails>
            {["L1", "L2", "L3"].map((item) => {

                let path = product.path, credentials = []

                switch (product.name) {
                    case "Meddy-Care":
                        credentials = [{
                            un: "AAAAAA",
                            pw: "Password1!"
                        }]
                        switch (item) {
                            case "L2":
                                path = productL2.path
                                credentials = [{
                                    un: "AAAAAA",
                                    pw: "Password1!"
                                },]
                                break
                            case "L3":
                                path = productL3.path
                                credentials = []
                                break;
                            default:
                                break;
                        }
                        break
                    case "Poliamby":
                        credentials = [{
                            un: "AAAAAA",
                            pw: "Password1!"
                        }, {
                            un: "admin01",
                            pw: "Password1!"
                        }]
                        switch (item) {
                            case "L2":
                                path = productL2.path
                                credentials = [{
                                    un: "AAAAAA",
                                    pw: "Password1!"
                                }, {
                                    un: "admin01",
                                    pw: "Password1!"
                                },{
                                    un: "keefe01",
                                    pw: "Password1!"
                                },{
                                    un: "larissa01",
                                    pw: "Password1!"
                                },{
                                    un: "geoffrey01",
                                    pw: "Password1!"
                                }]
                                break
                            case "L3":
                                path = productL3.path
                                credentials = [{
                                    un: "keefe01",
                                    pw: "Password1!"
                                },{
                                    un: "larissa01",
                                    pw: "Password1!"
                                },{
                                    un: "geoffrey01",
                                    pw: "Password1!"
                                }]
                                break;
                            default:
                                break;
                        }
                        break
                    case "Residenza digitale":
                        credentials = [{
                            un: "AAAAAA",
                            pw: "Password1!"
                        }]
                        switch (item) {
                            case "L2":
                                path = productL2.path
                                credentials = [{
                                    un: "AAAAAA",
                                    pw: "Password1!"
                                }]
                                break
                            case "L3":
                                path = productL3.path
                                credentials = []
                                break;
                            default:
                                break;
                        }
                        break
                    case "Mainframe":
                        credentials = [{
                            un: "checkTokenVero@test.tech4care.eu",
                            pw: "Password2!"
                        },]
                        switch (item) {
                            case "L2":
                                path = productL2.path
                                credentials = [{
                                    un: "AAAAAA",
                                    pw: "Password1!"
                                },]
                                break
                            case "L3":
                                path = productL3.path
                                credentials = []
                                break;
                            default:
                                break;
                        }
                        break
                    case "ComuniBus":
                        credentials = [{
                            un: "AAAAAA",
                            pw: "Password1!"
                        },]
                        switch (item) {
                            case "L2":
                                path = productL2.path
                                credentials = [{
                                    un: "AAAAAA",
                                    pw: "Password1!"
                                },]
                                break
                            case "L3":
                                path = productL3.path
                                credentials = []
                                break;
                            default:
                                break;
                        }
                        break
                    case "ReLab":
                        credentials = [{
                            un: "AAAAAA",
                            pw: "Password1!"
                        },]
                        switch (item) {
                            case "L2":
                                path = productL2.path
                                credentials = [{
                                    un: "AAAAAA",
                                    pw: "Password1!"
                                },]
                                break
                            case "L3":
                                path = productL3.path
                                credentials = []
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }

                return <LevelComponent
                    key={product.id + item}
                    product={product}
                    item={item}
                    credentials={credentials}
                    path={path}
                />
            })}
        </AccordionDetails>
    </Accordion>

}


const LevelComponent = ({product, item, credentials, path}) => {


    return <Stack
        marginBottom={"10px"}

    >

        <Stack
            direction={"row"}
            alignItems={"center"}
        >
            <Typography variant={"semiTitle"}>
                {item}
            </Typography>
            {path && <Button variant={"contained"}
                             color={"primary"}
                             sx={{marginLeft: "10px"}}
                             endIcon={<OpenInNew/>}
                             onClick={() => {
                                 window.open(
                                     path, "_blank");
                             }}>
                {path}
            </Button>}
        </Stack>
        <Grid container>
            {credentials.map((credential, index) => {

                return <CredentialComponent
                    key={product.id + item + credential.un}
                    {...{credential, item, index, product}}
                />
            })}
        </Grid>
    </Stack>
}


const CredentialComponent = ({credential, item, index, product}) => {
    let [isDialogOpen, setIsDialogOpen] = useState(false)
    let [accounts, setAccounts] = useState([])

    return <Grid item xs={12} sm={12} md={6} lg={4} xl={2}

                 sx={{padding: "10px"}}
    ><Paper
        sx={{padding: "10px"}}
    >
        <SimpleDialog
            isDialogOpen={isDialogOpen}
            dialogContent={<>
                {
                    accounts && accounts.map((account) => {
                        return <Stack key={"account" + account.id}
                                      direction={"row"}
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                      sx={{padding: "10px"}}>
                            {account.customerRoleDto.role}
                            <Button
                                color={"primary"}
                                variant={"contained"}
                                onClick={async () => await loginRedirect(credential, item, account.productLink, account.id)}
                            >
                                <ArrowForward/>
                            </Button>
                        </Stack>
                    })
                }
            </>}
            handleClose={() => setIsDialogOpen(false)}
            dialogTitle={<Typography variant={"title"}>Pick the istance</Typography>}
            dialogActions={<Button color={"secondary"} variant={"contained"}
                                   onClick={() => setIsDialogOpen(false)}
            >Close</Button>}

        />
        <Stack>
            <Stack direction={"row"}
            >
                <Person sx={{marginRight: "10px"}} color={"success"}/>
                <Typography variant={"semiTitle"}>
                    Account #{index + 1}
                </Typography>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}
                   marginTop={"10px"}
                   paddingLeft={"10px"}
            >
                <Typography variant={"semiTitle"}>
                    UN: {credential.un}
                </Typography>
            </Stack>

            <Stack direction={"row"} justifyContent={"space-between"} paddingLeft={"10px"}>
                <Typography variant={"semiTitle"}>
                    PW: {credential.pw}
                </Typography>
            </Stack>
            <Stack direction={"row"} justifyContent={"flex-end"} marginTop={"10px"}
                   paddingRight={"10px"}>
                <Tooltip title={"Login with " + credential.un}><Button
                    onClick={async () => {
                        let accounts = await getAccounts(credential, item)
                        setAccounts(accounts.filter(x => x.productName === product.name))
                        setIsDialogOpen(true)
                    }}
                    variant={"contained"}
                    color={"primary"}>
                    <Login/>
                </Button>
                </Tooltip>
            </Stack>
        </Stack>

    </Paper>
    </Grid>
}

const getBasePath = (item) => {
    let basePath = "https://api-test.tech4care.eu/login"
    switch (item) {
        case "L2":
            basePath = "https://l2-api.tech4care.eu/login"
            break
        case "L3":
            basePath = "https://l3-apiv2.tech4care.eu/login-2.7.0"
            break;
        default:
            break;
    }
    return basePath
}

const getAccounts = async (credential, item) => {
    let basePath = getBasePath(item)
    let res = await axios.request({
        url: basePath + "/api/v1/auth/sign-in",
        method: "GET",
        auth: {
            username: credential.un,
            password: credential.pw,
        },
    });

    return res.data
}
const loginRedirect = async (credential, item, path, id) => {


    let basePath = getBasePath(item)

    let res = await axios.request({
        url: basePath + "/api/v1/auth/sign-in/" + id,
        method: "GET",
        auth: {
            username: credential.un,
            password: credential.pw,
        },
    });


    window.open(
        path + "/loggedOut/?token=" + res.data.toString(), "_blank");

}
