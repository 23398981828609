import {Dialog, DialogActions, DialogTitle} from "@mui/material";


export function SimpleDialog(props) {
    const {isDialogOpen, dialogTitle,dialogContent,handleClose,dialogActions} = props;


    return (
        <Dialog onClose={handleClose} open={isDialogOpen} PaperProps={{sx:{overflow:"hidden"}}}>
            {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
            {dialogContent}
            {dialogActions &&<DialogActions>{dialogActions}</DialogActions>}
        </Dialog>
    );
}