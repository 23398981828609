export const primaryColor = "#1976d2";
export const getGeneralFontFamilyCustomizable = () => {
    return localStorage.getItem("--font-family") || getComputedStyle(document.documentElement)
        .getPropertyValue("--font-family")
}
export const getGeneralFontSizeCustomizable = () => {
    let output = localStorage.getItem("--font-size") || parseInt(getComputedStyle(document.documentElement)
        .getPropertyValue("--font-size"))
    if (output.replace) {
        return parseInt(output.replace("px", ""))
    } else {
        return output;
    }

}

export const getPrimaryColorCustomizable = () => {
    return localStorage.getItem("--first-color") || primaryColor
};
export const primaryColorText = "#fff";
export const getPrimaryColorTextCustomizable = () => {
    return localStorage.getItem("--button-text-color") || primaryColorText
};


export const secondaryColor = "#F23041";
export const getSecondaryColorCustomizable = () => {
    return localStorage.getItem("--second-color") || secondaryColor
};

export const getTextColorCustomizable = () => {
    return localStorage.getItem("--text-default-color") || primaryColor
};

export const getStickyHeaderColor = () => {
    return localStorage.getItem("--table-header-background-color") || "#FAFAFA"
}
export const getCardBackground = () => {
    return localStorage.getItem("--card-background") || "#FAFAFA"
}

export const BASE_THEME_CUSTOMIZATION = (defaultTheme) => ({
    palette: {
        primary: {
            main: getPrimaryColorCustomizable(),
            contrastText: getPrimaryColorTextCustomizable(),
            white: "white",
            greyIcon: "rgba(0, 0, 0, 0.23)",
            backgroundDefault: "#fafafa",
            oddRow: "#f0f0f0",
            text: "#757575",
            warning: "#ffb800"
        },
        secondary: {
            main: getSecondaryColorCustomizable(),
            contrastText: getPrimaryColorTextCustomizable()
        },
        background: {
            avatarPopoverChild: "rgba(255, 255, 255, 0.16)",
            whiteIconTransparent: "rgba(255, 255, 255, 0.56)"
        },
        success: {
            main:"#4e9b23"
        }
    },
    typography: {
        fontFamily: getGeneralFontFamilyCustomizable(),
        // fontSize: getGeneralFontSizeCustomizable(),
        body1: {
            color: getTextColorCustomizable()
        },
        titleDetail: {
            color: "var(--title-color)",
            fontSize: "36px",
            fontWeight: 200,
            lineHeight: "120%",
            letterSpacing: "-0.5px"
        },
        title: {
            color: "var(--title-color)",
            fontSize: "40px",
            fontWeight: 300,
            lineHeight: "120%",
            letterSpacing: "-0.5px"
        },
        semiTitle: {
            color: "var(--text-color)",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "133%",
            letterSpacing: "-0.5px"
        }, subTitle: {
            color: "var(--text-color)",
            fontSize: "17px",
            fontWeight: 400,
            lineHeight: "133%",
            letterSpacing: "-0.5px"
        }, smallText: {
            color: "var(--text-color)",
            fontSize: "15px",
            fontWeight: 400,
            lineHeight: "150%",
            letterSpacing: "-0.5px"
        },
        miniText:
            {
                color: "var(--text-color)",
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "150%",
                letterSpacing: "-0.5px"
            }
        ,
        microText: {
            color: "#9f9f9f",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "150%",
            letterSpacing: "-0.5px"
        },
        noData: {
            color: "var(--text-color)",
            fontSize: "30px",
            fontWeight: 400,
            lineHeight: "120%",
            letterSpacing: "-0.5px"
        }
    },
    overrides: {
        MuiTab: {textColorPrimary: {color: getTextColorCustomizable()}},
        MuiDialogTitle: {
            root: {
                borderBottom: "1px solid #E6E6E6",
                opacity: 1,
                cursor: "move",
                maxHeight: "80px",
                padding: "10px 15px!important",
            },
        },
        MuiDialogContent: {
            root: {
                fontSize: "10px",
                overflow: "auto",
            },
        },
        MuiDialogActions: {
            root: {
                borderTop: "1px solid #E6E6E6",
                opacity: 1,
                padding: "15px 40px 20px",
                justifyContent: "space-between"
            },
        },
        MuiInput: {
            underline: {
                "&:after": {
                    borderBottom: `2px solid var(--first-color)`,
                },
                "&$focused:after": {
                    borderBottomColor: "var(--first-color)",
                },
                "&$error:after": {
                    borderBottomColor: `var(--second-color)`,
                },
                "&:before": {
                    borderBottom: `1px solid var(--first-color)`,
                },
                "&:hover:not($disabled):not($focused):not($error):before": {
                    borderBottom: `2px solid var(--first-color)`,
                },
                "&$disabled:before": {
                    borderBottom: `1px dotted var(--first-color)`,
                },
            },
        },
        MuiFormLabel: {
            root: {
                color: getTextColorCustomizable()
            }
        },
        MuiListItem: {
            root: {
                "&$selected": {backgroundColor: "transparent"},
                "&$selected:hover": {backgroundColor: "transparent"},
            },
        },
        MuiMenuItem: {
            root: {
                "&:hover": {
                    backgroundColor: "transparent",
                },
            },
        },
        MuiCheckbox: {
            root: {
                color: primaryColor,
                fontSize: "large",
            },
        },
        MuiButton: {
            outlinedSizeLarge: {
                fontWeight: "bold",
                border: "2px solid " + primaryColor,
            },
        },
        MuiIconButton: {
            root: {
                "&:hover": {
                    backgroundColor: "none",
                },
            },
        },
        MuiInputBase: {
            root: {
                color: getTextColorCustomizable(),
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "var(--first-color)",
                },
            },
            input: {
                fontSize: "14px",
            },
        },
        MuiOutlinedInput: {
            root: {
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "var(--first-color)",
                },
            },
        },
        "&.Mui-selected": {
            outline: "none",
        },
        MuiTableCell: {
            stickyHeader: {
                backgroundColor: getStickyHeaderColor()
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: getCardBackground()
            }
        }
    },
});

//TODO sta roba serve per quando mettiamo in hook interfaceApp
// export const themeConstants = (defaultTheme) => ({
//     typography: {
//         fontSize: 12,
//         title: {
//             color: "red"
//         },
//         h3: {
//             color: "red"
//         }
//     },
//     palette: {
//         mode: 'dark',
//         primary: {
//             light: '#757ce8',
//             main: '#3f7ec0',
//             dark: '#3f7ec0',
//             contrastText: '#fff',
//         }
//     },
//     components: {
//         MuiButton: {
//             styleOverrides: {
//                 root: {
//                     textTransform: "camelcase"
//                 }
//             }
//         },
//         MuiLinearProgress: {
//             styleOverrides: {
//                 root: {
//                     borderRadius: "10px"
//                 }
//             }
//         },
//         MuiListItemButton: {
//             styleOverrides: {
//                 root: {
//                     borderRadius: "20px",
//                     paddingLeft: "10px",
//                     paddingRight: "10px",
//                 }
//             }
//         },
//         MuiTableContainer: {
//             styleOverrides: {
//                 root: {
//                     height: "100%",
//                 },
//             },
//         },
//         MuiContainer: {
//             styleOverrides: {
//                 root: {
//                     paddingLeft: "0px",
//                     paddingRight: "0px",
//                     height: "100%",
//                     [defaultTheme.breakpoints.up('xs')]: {
//                         paddingLeft: "0px",
//                         paddingRight: "0px",
//                     }
//                 },
//             },
//         },
//     },
// })