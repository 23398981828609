import React, {useState} from 'react';
import {Button, Card, CardActions, CardContent, Fab, Grid, Stack, TextField, Typography} from "@mui/material";
import axios from "axios";
import {Add} from "@mui/icons-material";


const populate = (key, value) => {
    let temp = document.querySelectorAll("[placeholder=" + key + "]");
    for (let e of temp) {
        e.setAttribute("value", value);
        e.value = value;
        e.focus();
        const evt = new Event("change", {"bubbles": true, "cancelable": false});
        e.dispatchEvent(evt);
        const easdsavt = new KeyboardEvent('keypress', { key: "a" });
        e.dispatchEvent(easdsavt);
    }
}

function LoginComponent(props) {
    let {basePath, callback} = props
    const [userSelected, setUserSelected] = useState(false)
    const [users, setUsers] = useState([])
    const [username, setUsername] = useState("AAAAAA")
    const [password, setPassword] = useState("Password1!")

    const getAccounts = async () => {
        let res = await axios.request({
            url: basePath + "login/api/v1/auth/sign-in",
            method: "GET",
            auth: {
                username: username,
                password: password,
            },
        });
        setUsers(res.data);
        setUserSelected(false);
        console.log("Users: ", res.data)
    }
    const handleLogin = async (u) => {
        let {id} = u;
        let res = await axios.request({
            url: basePath + "login/api/v1/auth/sign-in/" + id,
            method: "GET",
            auth: {
                username: username,
                password: password,
            },
        });
        setUserSelected(u);
        if (callback) {
            callback(res.data.toString())
        }
    }
    return (
        <Stack gap={2} direction={"column"}>
            <Fab
                sx={{
                    position: "fixed",
                    bottom: 0,
                    right: 0,
                }}
                color={"primary"}
                onClick={() => {
                    populate("limit", 10)
                    populate("offset", 0)
                    populate("customerGroupId", userSelected?.customerGroupDto?.id)
                }}>
                <Add/>
            </Fab>
            <Stack gap={2} direction={"row"}>
                <TextField id="username" label="Username" variant="outlined" value={username}
                           onChange={event => setUsername(event.target.value)}/>
                <TextField id="password" label="Password" variant="outlined" value={password}
                           onChange={event => setPassword(event.target.value)}/>
                <Button variant={"contained"} onClick={getAccounts}>Login</Button>
                {userSelected &&
                    <Stack alignItems={"center"}>
                        <Typography>Selected
                            user: {userSelected.fullName} {userSelected.customerRoleDto?.role} {userSelected.customerGroupDto?.name}</Typography>
                        <Stack alignItems={"center"} direction={"row"} gap={2}>
                            <Typography>Group id: {userSelected.customerGroupDto?.id}</Typography>
                            <Button color={"primary"}
                                    onClick={() => {
                                        navigator.clipboard.writeText(userSelected.customerGroupDto?.id)
                                    }} variant={"contained"}>Copy</Button>


                        </Stack>
                    </Stack>
                }
            </Stack>
            <Stack sx={{display: userSelected ? "none" : undefined}} gap={2} direction={"row"}>
                <Grid container gap={2}>
                    {users.map(u => {
                        return <Grid key={u.id} item xs={3}>
                            <Card sx={{minWidth: 275}}>
                                <CardContent>
                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                        Company: {u.buyerName}
                                        <br/>
                                        Product: {u.productName}
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        Group: {u.customerGroupDto?.name}
                                    </Typography>
                                    <Typography sx={{mb: 1.5}} color="text.secondary">
                                        Role: {u.customerRoleDto?.role}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => handleLogin(u)} variant={"contained"}
                                            size="small">Select</Button>
                                </CardActions>
                            </Card>
                        </Grid>

                    })}
                </Grid>
            </Stack>
        </Stack>
    );
}

export default LoginComponent;
