import React, {useEffect, useState} from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import {Grid, InputLabel, MenuItem, Select} from "@mui/material";
import LoginComponent from "./LoginComponent";


function Swagger() {
    const [token, setToken] = useState()
    const url = "/api/v1/openapi.json"
    const [basePath, setBasePath] = useState(localStorage.getItem("uniqueUtilsBasePath") || "https://api-test.tech4care.eu/")
    const [module, setModule] = useState(localStorage.getItem("uniqueUtilsModule") || "residenzeaperte")

    useEffect(() => {
        localStorage.setItem("uniqueUtilsBasePath", basePath)
        localStorage.setItem("uniqueUtilsModule", module)
    }, [basePath, module])

    const handleChangeBasePath = (event) => {
        setBasePath(event.target.value);
    };
    const handleChangeModule = (event) => {
        setModule(event.target.value);
    };
    console.log("Check base path: ", basePath + module + url, basePath)
    return (
        <Grid container spacing={2} style={{padding: "20px", overflow: "auto"}}>
            <Grid item>
                <InputLabel id="basePath">Enviroment</InputLabel>
                <Select
                    labelId="basePath"
                    id="basePath"
                    value={basePath}
                    onChange={handleChangeBasePath}
                    autoWidth
                    label="BasePath"
                >
                    <MenuItem value="https://api-test.tech4care.eu/">
                        L1
                    </MenuItem>
                    <MenuItem value="https://api-test.tech4care.eu/mic-check/">
                        L1 - miccheck
                    </MenuItem>
                    <MenuItem value={"http://localhost:8080/"}>Local</MenuItem>
                    <MenuItem value={"http://localhost:8080/mic-check/"}>Local - miccheck</MenuItem>
                    <MenuItem value={"https://l2-api.tech4care.eu/"}>L2</MenuItem>
                    <MenuItem value={"https://l3-apiv2.tech4care.eu/"}>L3</MenuItem>
                </Select>
            </Grid>
            <Grid item>
                <InputLabel id="module">Module</InputLabel>
                <Select
                    labelId="module"
                    id="module"
                    value={module}
                    onChange={handleChangeModule}
                    autoWidth
                    label="Module"
                >
                    <MenuItem value={"mainframe"}>mainframe</MenuItem>
                    <MenuItem value={"magicadvanced"}>magicadvanced</MenuItem>
                    <MenuItem value={"residenzeaperte"}>residenzeaperte</MenuItem>
                    <MenuItem value={"meddy"}>meddy</MenuItem>
                    <MenuItem value={"relabvr"}>relabvr</MenuItem>
                    <MenuItem value={"comunibus"}>comunibus</MenuItem>
                    <MenuItem value={"hygeia"}>hygeia</MenuItem>
                    <MenuItem value={"poliamby"}>poliamby</MenuItem>
                    <MenuItem value={"monitoring"}>monitoring</MenuItem>
                    <MenuItem value={"disease"}>disease</MenuItem>
                    <MenuItem value={"drug"}>drug</MenuItem>
                    <MenuItem value={"goal"}>goal</MenuItem>
                    <MenuItem value={"target"}>target</MenuItem>
                    <MenuItem value={"measurement"}>measurement</MenuItem>
                    <MenuItem value={"document"}>document</MenuItem>
                    <MenuItem value={"diary"}>diary</MenuItem>
                    <MenuItem value={"login"}>login</MenuItem>
                    <MenuItem value={"activity"}>activity</MenuItem>
                    <MenuItem value={"personal-data"}>personal data</MenuItem>
                    <MenuItem value={"medical-report"}>medical-report</MenuItem>
                    <MenuItem value={"survey"}>survey</MenuItem>
                    <MenuItem value={"resident-senior"}>resident senior</MenuItem>
                </Select>
            </Grid>
            <Grid item>
                <LoginComponent key={basePath} basePath={basePath} callback={(token) => {
                    setToken(token)
                }}/>
            </Grid>
            <Grid item xs={12}>
                <SwaggerUI
                    tryItOutEnabled={true}
                    persistAuthorization={true}
                    showMutatedRequest={true}
                    requestInterceptor={(req) => {
                        //TODO Sicuramente si può gestire meglio, va sostituito il base path a quello inziale, oppure a quello a cui è già stato sostituito
                        console.log("URL REQUEST: ", req.url, basePath,)
                        if (req.url.includes("/" + module + "/api")) {
                            if (req.url.includes("https://api-test.tech4care.eu/" + module + "/api")) {
                                req.url = req.url.replace("https://api-test.tech4care.eu/", basePath)
                                req.url = req.url.replace(basePath, basePath)
                            } else {
                                //Replace the substring before the module
                                console.log("CHeck here: ", req.url.substring(0, req.url.indexOf(module)), basePath)
                                req.url = req.url.replace(req.url.substring(0, req.url.indexOf(module)), basePath)
                                // req.url = req.url.replace(basePath, basePath)
                            }
                        } else {
                            req.url = req.url.replace("https://api-test.tech4care.eu/", basePath + module + "/")
                            req.url = req.url.replace(basePath, basePath + module + "/")
                        }
                        console.log("URL REQUEST3: ", req.url, basePath,)
                        if (token) {
                            req.headers["Authorization"] = "Bearer " + token
                        }
                        return req
                    }}
                    responseInterceptor={(res) => {
                        return res
                    }}
                    key={basePath + module + url}
                    url={basePath + module + url}
                />
            </Grid>
        </Grid>

    );
}

export default Swagger;

